import React from "react";
import "./patrocinadoresContainer.css";
import Patrocinador from "./patrocinador/Patrocinador";
import patrocinadoresData from "./patrocinadores.json";


const PatrocinadoresContainer = () => {
  return (
    <div className="patrocinadoresContainer">
      <div className="patrocinadoresCarousel">
        <div className="patrocinadoresGroup">
          {patrocinadoresData.map((patrocinador, index) => (
            <div key={index} className="slide">
              <Patrocinador
                imagen={patrocinador.imagen}
                nombrePatrocinador={patrocinador.nombrePatrocinador}
                urlPatrocinador={patrocinador.urlPatrocinador}
              />
            </div>
          ))}
        </div>
        <div aria-hidden className="patrocinadoresGroup">
          {patrocinadoresData.map((patrocinador, index) => (
            <div key={index} className="slide">
              <Patrocinador
                imagen={patrocinador.imagen}
                nombrePatrocinador={patrocinador.nombrePatrocinador}
                urlPatrocinador={patrocinador.urlPatrocinador}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatrocinadoresContainer;
