import React from "react";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import "./navButton.css";

const NavButton = ({ name, route }) => {
  return (
    <Link to={route} className="nav-link">
      {" "}
      {name}
    </Link>
  );
};

export default NavButton;
