import React from "react";
import { NavLink } from "react-router-dom"; // Cambiamos Link por NavLink
import { useTranslation } from "react-i18next";
import "./header.css";
import beltzakLogo from "../../assets/logoBeltzak.webp";
import NavButton from "./navButton/NavButton";
import { Link } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();

  // Función para cambiar el idioma
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header">
      <NavLink to="/" className="logo-container">
        <img src={beltzakLogo} alt="Beltzak logo" className="logo" />
        <h1 className="title">BELTZAK RUGBY</h1>
      </NavLink>
      <nav>
        <ul className="nav-list">
          {/* <li>
            <NavLink
              to="/tienda"
              className={({ isActive }) => (isActive ? "nav-active" : "")}
            >
              <NavButton name={t("tienda")} route="/tienda" />
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/juegaConNosotros"
              className={({ isActive }) => (isActive ? "nav-active" : "")}
            >
              <NavButton
                name={t("juegaConNosotros")}
                route="/juegaConNosotros"
              />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contacto"
              className={({ isActive }) => (isActive ? "nav-active" : "")}
            >
              <NavButton name={t("contacto")} route="/contacto" />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="https://www.flickr.com/photos/201757840@N07/albums/"
              className={({ isActive }) => (isActive ? "nav-active" : "")}
            >
              <NavButton
                name={t("foto")}
                route="https://www.flickr.com/photos/201757840@N07/albums/"
              />
            </NavLink>
          </li>
          <li className="language-flag-container">
            <button className="idiomaButton" onClick={() => changeLanguage("es")}>
              es
            </button>
            <button className="idiomaButton" onClick={() => changeLanguage("eu")}>
              eus
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
