import React from "react";
import { useTranslation } from "react-i18next";
import "./historiaBeltzak.css";
import foto1 from "../../assets/historiaFotos/Primer-partido-Lasarte-RT-1985_1.webp";
import foto2 from "../../assets/historiaFotos/Beltzak_hipodromo 1990.webp";
import foto3 from "../../assets/historiaFotos/gira-escocia-1990-murrayfield-gradas_1.webp";
import foto4 from "../../assets/historiaFotos/Primer-entrena-eskola-2010_1.webp";
import foto5 from "../../assets/historiaFotos/jesus-erro_1.webp";
const HistoriaBeltzak = () => {
  const { t } = useTranslation();

  return (
    <div className="historia-beltzak-container">
      <h2 className="historia-title">{t("historiaBeltzakTitle")}</h2>
      <div className="historia-content">
        <div className="historia-text">
          <p className="historia-paragraph">
            {t("historiaBeltzak1")}
            <img src={foto1} alt="Beltzak team" className="paragraph-image" />
          </p>
          <p className="historia-paragraph">
            <img src={foto2} alt="Beltzak team" className="paragraph-image" />
            {t("historiaBeltzak2")}
          </p>
          <p className="historia-paragraph">
            {t("historiaBeltzak3")}
            <img src={foto3} alt="Beltzak team" className="paragraph-image" />
          </p>
          <p className="historia-paragraph">{t("historiaBeltzak4")}</p>
          <p className="historia-paragraph">
            {t("historiaBeltzak5")}
            <img src={foto4} alt="Beltzak team" className="paragraph-image" />
          </p>
          <p className="historia-paragraph">
            <img src={foto5} alt="Beltzak team" className="paragraph-image" />
            {t("historiaBeltzak6")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HistoriaBeltzak;
