// Footer.js
import React from "react";
import "./footer.css"; // Importa el archivo de estilos si lo tienes
import Social from "../Social/Social";


const Footer = () => {
  return (
    <footer className="footer">
      <p>2025 Beltzak Rugby</p>
      <Social />
    </footer>
  );
};

export default Footer;
