//TiendaContainer.jsx
import React from 'react';
import "./tiendaContainer.css";
import { useTranslation } from 'react-i18next';
import Destacado from './Destacado/Destacado';
import Tienda from './tienda/Tienda';
import { FaExclamationCircle } from "react-icons/fa";



const TiendaContainer = () => {

  const { t } = useTranslation();
  return (
    <div className='tienda'>
      
       <h1>
       <FaExclamationCircle className="exclamation-icon" />
        {t('tiendaAviso')}
        </h1>
        <Destacado/>
        <Tienda/>
    </div>
  );
};

export default TiendaContainer;
