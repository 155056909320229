import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: {
          //nav
          tienda: "Tienda",
          foto: "Fotos",
          juegaConNosotros: "Juega Con Nosotros",
          contacto: "Contacto",

          //tienda
          tiendaAviso: "Atención, esto es solo un catálogo, para comprar algo contacta con Richard.",
          historiaBeltzakTitle: "HISTORIA DEL CLUB",
          historiaBeltzak1: "El primer equipo de Rugby de Lasarte-Oria se forma en septiembre de 1984, a raíz del contacto mantenido entre componentes de la comisión de fiestas del barrio Sasoeta y Jesús Erro Castillo, fundador de la E.H.R.E.I. (Euskal Herriko Rugby Eskola Independentea), dentro de la cual surgieron más de 20 equipos de Rugby a lo largo de su historia. Se da la circunstancia de que, aunque había habido anteriormente vecinos de Lasarte jugando Rugby en otros equipos, hasta esta fecha no se había constituido ningún equipo en Lasarte-Oria.",
          historiaBeltzak2: "Durante varios años los entrenamientos tuvieron lugar en el interior del recinto del Hipódromo, los fines de semana, y en la carretera frente al Dana-Ona, entre semana, ya que no había otros lugares en el pueblo para la práctica de este deporte. Si bien algunos jugadores ya habían debutado en Sara y Donibane Lohitzun formando parte del Anoeta, el primer XV de Lasarte jugó en el antiguo campo de Anoeta (en el lugar donde se encuentra actualmente el estadio de fútbol de Anoeta) en enero de 1985. En 1988 el equipo se empezó a denominar “Beltzak” debido al color negro de la nueva equipación.",
          historiaBeltzak3: "Aunque no recibieron ningún tipo de ayuda de institución alguna ni estuvieron adscritos a ninguna liga federada, los Beltzak jugaron más de 200 partidos, la mayoría con equipos de Iparralde, y realizaron tres giras por Europa (Francia, Alemania y Holanda en 1986, Inglaterra y Escocia en 1990 e Irlanda en 1992).",
          historiaBeltzak4: "Desde 1998, dejaron de jugar más partidos, excepto algunos partidos de veteranos. Sin embargo, muchos integrantes de los Beltzak siguieron manteniendo el contacto para ver juntos partidos. Incluso organizaron dos giras de veteranos: una para presenciar partidos del Mundial de Rugby en Edimburgo en 2007 y la otra para asistir a un partido del Seis Naciones en Roma en 2008.",
          historiaBeltzak5: "Tras la celebración en 2009 del 25 aniversario de la creación del equipo, en 2010 se decidió la creación de la escuela de Rugby Beltzak-Ostadar (con 39 jugadores de distintas categorías) y la colaboración con Ttakun Kultur Elkartea en el ámbito del deporte escolar, con la aportación de monitores y material de Rugby.",
          historiaBeltzak6: "Debemos dar las gracias a todas las personas que nos han ayudado a lo largo de todos estos años, empezando por la gente de la comisión de fiestas de Sasoeta de aquellos años (Julián Espadas, Andrés, Tito, Marce…), Querejeta, Soroeta (por facilitarnos el acceso al Hipódromo) y a todos aquellos que han aportado cualquier cosa a ese proyecto. Mostrar sobre todo nuestro agradecimiento a Jesús Erro Castillo, verdadero motor de aquel equipo, el que nunca faltaba y el que consiguió inculcar a unos chavales de barrio de 17 años, unos valores y el amor por un deporte que tiene mucho que ofrecer tanto en lo deportivo como en lo personal.",

          //Entrenamientos Info
          Entrenamientos: "Información de los Entrenamientos",
          Senior: "Todos los jueves y viernes a las 19:00 en el nuevo campo de Atsobakar. (un jueves en Atsobakar y otro en Puio, Donostia)",
          Senior2: "Todos los lunes y miercoles a las 19:00 en el nuevo campo de Atsobakar.",
          sub18: "Todos los martes y jueves a las 18:00 en Asti, Zarautz. (miercoles gym 18:15).",
          sub16: "Todos los martes y jueves a las 18:00 en Asti, Zarautz. (miercoles gym 18:15).",
          sub14: "Todos los miercoles a las 19:00 en el nuevo campo de Atsobakar, Lasarte-Oria.",
          sub12: "Todos los viernes a las 18:15 en el nuevo campo de Atsobakar, Lasarte-Oria.",

          //Contacto
          correo: "Correo Electrónico",
          presidente : "Presidente",
          responsableTienda: "Responsable de la Tienda",
          redes: "Responsable de Redes Sociales",

          // Agrega aquí más traducciones al español
        }
      },
      eu: {
        translation: {
          //nav
          tienda: "Denda",
          foto: "Argazkiak",
          juegaConNosotros: "Gurekin Jolastu",
          contacto: "Kontaktua",

          //tienda
          tiendaAviso: "Adi, hau katalogo bat besterik ez da, zerbait erosteko, jarri harremanetan Richardekin",
          historiaBeltzakTitle: "KLUBAREN HISTORIA",
          historiaBeltzak1: "Lasarteko lehen errugbi-taldea 1984ko irailean sortu zen. Sasoeta auzoko jai batzordeko kideen eta Jesus Erro Castilloren, E.H.R.E.I. (Euskal Herriko Rugby Eskola Independentea) ren sortzailearen, arteko harremanaren ondorioz. Kirol erakunde honen eraginez 20 errugbi-talde baino gehiago sortu dira. Lehenago lasartearrak errugbian ibili izan baziren ere, beste herrietako taldeetan, ordura arte ez zen talderik eratu Lasarte-Orian.",
          historiaBeltzak2: "Hainbat urtetan entrenamenduak hipodromoaren esparruaren barruan egin ziren asteburuetan, eta Dana-Ona arteko errepidean aste tartean, herrian ez bait zegoen beste lekurik. Nahiz eta jokalari batzuk debuta egin izan Sara eta Donibane Lohitzunen, Anoeta taldearekin, Lasarteko lehen hamabostekoak Anoetako zelai zaharrean (gaur egun Anoetako futbol-zelaian) jokatu zuen, 1985eko urtarrilean. 1988an taldea Beltzak deitzen hasi zen, ekipamendu berriaren kolore beltza zela eta.",
          historiaBeltzak3: "Inolako erakunderen laguntzarik jaso ez eta liga federatu bati atxikita egon ez arren, Beltzek 200 partida baino gehiago jokatu zituzten, gehienak Iparraldeko taldeekin, eta hiru bira egin zituzten Europan (Frantzia, Alemania eta Herbehereak 1986an, Ingalaterra eta Eskozia 1990ean eta Irlanda 1992an).",
          historiaBeltzak4: "1998tik, partida gehiago jokatzeari utzi zioten, beteranoen partida batzuk izan ezik. Hala ere, Beltzetako kide askok harremanari eutsi zioten partidak elkarrekin ikusteko. Beteranoen bi bira ere antolatu zituzten: Edinburgoko Errugbiko Munduko Txapelketara, 2007an, eta 2008an Sei Nazioen Erromako partida batera.",
          historiaBeltzak5: "2009an, taldea sortu zeneko 25. urteurrena ospatu ondoren, 2010. urtean erabaki zen Beltzak-Ostadar errugbi eskola sortzea eta Ttakun Kultur Elkartearekin lankidetzan aritzea eskola kirolaren arloan, errugbiko monitoreak eta materiala emanaz.",
          historiaBeltzak6: "Eskerrak eman behar dizkiegu urte hauetan guztietan lagundu diguten guztiei. urte haietako Sasoetako Jai Batzordeko jendearengandik hasita (Julian Espadas, Andrés, Tito, Marce… ), Querejeta, Soroeta (hipodromora sartzen laguntzeagatik) eta proiektu horretan edozer ekarpen egin duten guztiei. Eta batez ere, Jesus Erro Castillori, talde haren benetako motorra izanagatik, eta Lasarteko 17 urteko gazte batzuei kirol-arloan eta arlo pertsonalean eskeintzeko hainbeste duen kirola erakusteagatik.",

          //Entrenamientos Info
          Entrenamientos: "Entretenamenduen Informazioa",
          Senior: "Ostegun eta ostiralero 19:00etan Atsobakar zelai berrian.",
          Senior2: "Astelehen eta asteazkenero 19:00etan Atsobakar zelai berrian.",
          sub18: "Asteartero eta ostegunero 18:00etan Astin, Zarautzen. (asteazkena gym 18:15etan).",
          sub16: "Asteartero eta ostegunero 18:00etan Astin, Zarautzen. (asteazkena gym 18:15etan).",
          sub14: "Asteazkenero 19:00etan Lasarte-Oriako Atsobakar zelai berrian.",
          sub12: "Ostiralero 18:15etan Lasarte-Oriako Atsobakar zelai berrian. ",


          //Contacto
          correo: "Posta Elektronikoa",
          presidente : "Presidente",
          responsableTienda: "Dendako arduraduna",
          redes: "Sare sozialen arduraduna",

          // Agrega aquí más traducciones al euskara
        }
      }
    },
    lng: "eu", // Idioma por defecto
    fallbackLng: "es", // Idioma de respaldo
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
