// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/header/Header.jsx';
import HistoriaBeltzak from './components/historia/HistoriaBeltzak.jsx';
import TiendaContainer from './components/tiendaContainer/TiendaContainer.jsx';
import PatrocinadoresContainer from './components/PatrocinadoresContainer/PatrocinadoresContainer.jsx';
import JuegaConNosotros from './components/juegaConNosotros/JuegaConNosotros.jsx';
import Contacto from './components/contacto/Contacto.jsx';
import Footer from './components/footer/Footer.jsx';
import "./i18n/i18n.js";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HistoriaBeltzak />} />
        <Route path="/tienda" element={<TiendaContainer />} />
        <Route path="/juegaConNosotros" element={<JuegaConNosotros/>} />
        <Route path="/contacto" element={<Contacto/>}/>
      </Routes>
      <PatrocinadoresContainer />
      <Footer />
    </Router>
  );
}

export default App;
