import React from "react";
import './patrocinador.css';

const loadImage = require.context(`../../../assets/patrocinadores`, true);

const Patrocinador = ({ imagen, nombrePatrocinador, urlPatrocinador }) => {
  

  return (
    <a href={urlPatrocinador} className="patrocinador-container" target="_blank" rel="noopener noreferrer">
      <img className="patrocinador-imagen" src={loadImage("./"+imagen)} alt={nombrePatrocinador} />
      <h3 className="patrocinador-nombre">{nombrePatrocinador}</h3>
    </a>
  );
};

export default Patrocinador;
