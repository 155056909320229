import React from "react";
import "./responsableCard.css";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

import { useTranslation } from "react-i18next";

const ResponsableCard = ({ nombre, labor, correoElectronico, foto }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Card className="cardResponsable">
      <CardMedia
        component="img"
        alt={nombre}
        height="140"
        image={foto}
        title={nombre}
      />
      <CardContent>
        <Typography variant="h5" component="h2">
          {nombre}
        </Typography>
        <Typography color="textSecondary">{labor}</Typography>
        <Typography variant="body2" component="p">
        {t("correo")}: {correoElectronico}
        </Typography>
      </CardContent>
    </Card>
  );
};

ResponsableCard.propTypes = {
  nombre: PropTypes.string.isRequired,
  labor: PropTypes.string.isRequired,
  correoElectronico: PropTypes.string.isRequired,
  foto: PropTypes.string.isRequired,
};

export default ResponsableCard;
