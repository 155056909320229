import React from "react";
import "./juegaConNosotros.css";
import { useTranslation } from "react-i18next";

const JuegaConNosotros = () => {
  const { t } = useTranslation();
  return (
    <div className="juegaConNosotros">
      <h1>{t("Entrenamientos")}</h1>
      <table className="trainingTable">
        <tbody>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Senior</h2>
            </td>
            <td className="trainingDetails">{t("Senior")}</td>
          </tr>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Senior femenino</h2>
            </td>
            <td className="trainingDetails">{t("Senior2")}</td>
          </tr>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Sub18</h2>
            </td>
            <td className="trainingDetails">{t("sub18")}</td>
          </tr>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Sub16</h2>
            </td>
            <td className="trainingDetails">{t("sub16")}</td>
          </tr>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Sub14</h2>
            </td>
            <td className="trainingDetails">{t("sub14")}</td>
          </tr>
          <tr className="trainingRow">
            <td className="trainingCategory">
              <h2>Sub12</h2>
            </td>
            <td className="trainingDetails">{t("sub12")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JuegaConNosotros;
