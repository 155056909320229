//Destacado.jsx
import React from "react";
import "./destacado.css";
//import { useTranslation } from "react-i18next";
import Articulo from "../articulo/Articulo";
import wales from "../../../assets/wales.png"
import { FaShoppingBasket } from "react-icons/fa";


const Destacado = () => {
  //const { t } = useTranslation();
  return (
    <div className="destacadoTienda">
      <div className="infoDestacado">
        <h1>Destacado</h1>
        <h2>¡Nuevo en la tienda!</h2>
        <FaShoppingBasket />
      </div>
      <Articulo
        nombre="Polo"
        descripcion="Polo temporada 2022"
        precio="24.99"
        foto = {wales}
        tallas={["S", "M", "L", "XL"]}
      />
      <Articulo
        nombre="Polo"
        descripcion="Polo temporada 2022"
        precio="24.99"
        foto = {wales}
        tallas={["S", "M", "L", "XL"]}
      />
      <Articulo
        nombre="Polo"
        descripcion="Polo temporada 2022"
        precio="24.99"
        foto = {wales}
        tallas={["S", "M", "L", "XL"]}
      />
    </div>
  );
};

export default Destacado;
