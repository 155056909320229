import ResponsableCard from "./responsable/ResponsableCard";
import "./contacto.css";
import beltzakLogo from "../../assets/logoBeltzak.webp";

import { useTranslation } from "react-i18next";

const Contacto = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="contacto">
      <h1>Contacto</h1>
      <div className="responsable">
        <ResponsableCard
          nombre="Manu"
          labor={t("presidente")}
          correoElectronico="maunra@hotmail.com"
          foto={beltzakLogo}
        />
        <ResponsableCard
          nombre="Richard"
          labor={t("responsableTienda")}
          correoElectronico="ritxar72@gmail.com"
          foto={beltzakLogo}
        />
        <ResponsableCard
          nombre="Jon Ibisate"
          labor={t("redes")}
          correoElectronico="jonibisate@gmail.com"
          foto={beltzakLogo}
        />
      </div>
    </div>
  );
};

export default Contacto;
