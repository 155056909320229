//Articulo.jsx
import React from "react";
import "./articulo.css";
//import { useTranslation } from "react-i18next";

const Articulo = ({ nombre, descripcion, precio, foto, tallas }) => {
  //const { t } = useTranslation();

  return (
    <div className="cardArticulo">
      <img src={foto} alt="foto" />
      <div className="infoPrecio">
        <h2>{nombre}</h2>
        <h2>{precio}€</h2>
      </div>
      <div className="tallas">
        <h3>Tallas disponibles:</h3>
        <ul>
          {tallas.map((talla, index) => (
            <li key={index}>{talla}</li>
          ))}
        </ul>
      </div>
      <p>{descripcion}</p>
     
    </div>
  );
};

export default Articulo;
