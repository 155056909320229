import React, { useEffect, useState } from 'react';
import productosList from '../productosList.json';
import Articulo from '../articulo/Articulo';
import './tienda.css';

const productoImages = require.context('../../../assets/productos', true);

const Tienda = () => {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        setProductos(productosList);
    }, []);

    return (
        <div className='tiendaContainer'> 
                {productos.map((producto) => (
                    <Articulo

                    nombre={producto.nombre}
                    descripcion={producto.descripcion}
                    precio={producto.precio}
                    foto={productoImages(`./${producto.imagen}`)}
                    tallas={producto.tallas}                 
                    ></Articulo>
                ))}
        </div>
    );
};

export default Tienda;